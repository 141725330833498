// i18next-extract-mark-ns-start bizum-for-prestashop
//Payment methods
import {AnchorLink} from 'components/AnchorLink';
import {Background} from 'components/Background';
import {ContactSalesButton} from 'components/ContactSalesButton';
import {Content} from 'components/Content';
import {Section, SectionActions, SectionHeader, SectionImage} from 'components/Section';
import {SEO} from 'components/SEO';
import {SignUpButton} from 'components/SignUpButton';
import {graphql} from 'gatsby';
import {Trans, useI18next} from 'gatsby-plugin-react-i18next';

//Partners
import index2 from 'images/best_payment_gateway_rates.svg';

//Images
import prestashop_bizum from 'images/prestashop_bizum.svg';

// Clients
import index1 from 'images/payment_gateway.svg';
import index3 from 'images/shopify_payment_gateway.svg';

import React from 'react';
import {List, OrderedList} from 'components/List';
import {IndexBackground} from 'components/landings/IndexBackground';
import {MainTitleImg} from 'components/combo-pages/MainTitleImg';
import {MainTitleContent} from 'components/combo-pages/MainTitleContent';
import {BlogLink} from 'components/links/Blog';
import {
  InternalPageLink,
  PaymentsOrchestrationLink,
  PrestashopPaymentGatewayLink,
  PricingLink,
  RedsysAlternativeLink
} from 'components/links/Pages';
import {SignUpLink} from 'components/links/Other';
import {SetupIntegration} from 'components/combo-pages/SetupIntegration';
import Bold from 'components/Bold';

const BizumForPrestashop: React.FC = () => {
  const {t} = useI18next();
  return (
    <>
      <SEO
        title={t('Bizum for PrestaShop | MONEI')}
        description={t(
          'Accept payments with Bizum in your PrestaShop store to stand out and increase your sales. Start now ››'
        )}
      />
      <IndexBackground>
        <Content>
          <Section reverseOnMobile>
            <MainTitleContent>
              <SectionHeader underline tagName="h1">
                <Trans>Get the Bizum PrestaShop integration</Trans>
              </SectionHeader>
              <Trans parent="p">
                Add Bizum to your PrestaShop store to access over one dozen million users of the
                most popular Spanish peer-to-peer mobile payment app. More than 11,000 online
                merchants already offer Bizum. Accept Bizum payments for PrestaShop to stay
                competitive and increase sales.
              </Trans>
              <SectionActions>
                <SignUpButton variant="light" style={{marginRight: 15}}>
                  <Trans>Open an Account</Trans>
                </SignUpButton>
                <ContactSalesButton />
              </SectionActions>
            </MainTitleContent>
            <MainTitleImg
              src={prestashop_bizum}
              alt="Woocommerce bizum"
              title="Woocommerce bizum"
            />
          </Section>
        </Content>
      </IndexBackground>
      <Background>
        <Content>
          <Section>
            <SectionImage
              src={index2}
              alt="MONEI Payment Gateway"
              title="MONEI Payment Gateway"
              width={600}
              height={350}
              mobileWidth={280}
            />
            <div>
              <SectionHeader>
                <Trans>Accept Bizum payments for PrestaShop (and more)</Trans>
              </SectionHeader>
              <Trans parent="p">
                Adding <InternalPageLink slug="payment-methods/bizum">Bizum</InternalPageLink> to
                your PrestaShop store will help you reach more customers in Spain, but it shouldn’t
                be the only{' '}
                <InternalPageLink slug="payment-methods">payment method</InternalPageLink> you
                accept — especially if you want to grow a{' '}
                <BlogLink slug="cross-border-ecommerce">cross-border e-commerce</BlogLink> business.
              </Trans>
              <Trans parent="p">
                Shoppers look for a range of payment methods for PrestaShop including{' '}
                <InternalPageLink slug="payment-methods/credit-cards">
                  credit cards
                </InternalPageLink>
                , <BlogLink slug="digital-wallet-ecommerce">Digital wallets</BlogLink> like{' '}
                <InternalPageLink slug="payment-methods/google-pay">Google Pay</InternalPageLink>,{' '}
                <InternalPageLink slug="payment-methods/apple-pay">Apple Pay</InternalPageLink>,{' '}
                <InternalPageLink slug="payment-methods/click-to-pay">
                  Click to Pay
                </InternalPageLink>
                , and <InternalPageLink slug="payment-methods/paypal">PayPal</InternalPageLink> and
                more local payment methods:
              </Trans>
              <List>
                <li>
                  <Trans>
                    <InternalPageLink slug="payment-methods/multibanco">
                      Multibanco
                    </InternalPageLink>{' '}
                    bank transfer (Portugal)
                  </Trans>
                </li>
                <Trans parent="li">
                  <InternalPageLink slug="payment-methods/bancontact">Bancontact</InternalPageLink>{' '}
                  bank transfer (Belgium)
                </Trans>
              </List>
              <Trans parent="p">
                Reach more people across Europe, improve the payment experience, and boost sales by
                accepting the widest range of payment methods using a single{' '}
                <PrestashopPaymentGatewayLink>
                  PrestaShop payment gateway
                </PrestashopPaymentGatewayLink>{' '}
                integration.
              </Trans>
              <SectionActions>
                <SignUpButton variant="dark">
                  <Trans>Discover MONEI</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
          </Section>
          <Section reverseOnMobile>
            <div>
              <SectionHeader>
                <Trans>Improve sales revenue with payments orchestration</Trans>
              </SectionHeader>
              <Trans parent="p">
                Boost your e-commerce sales and reduce false{' '}
                <BlogLink slug="online-payment-failure-message">
                  online payment failure messages
                </BlogLink>{' '}
                with a <BlogLink slug="frictionless-payments">frictionless payment</BlogLink>{' '}
                experience that results in <Bold>95% more approved payments</Bold>. Stop relying on{' '}
                <RedsysAlternativeLink>Redsys</RedsysAlternativeLink> alone. Use{' '}
                <PaymentsOrchestrationLink>payments orchestration</PaymentsOrchestrationLink> to
                diversify your payment processing. Set{' '}
                <AnchorLink href="https://support.monei.com/hc/articles/4412643058833">
                  payment routing rules
                </AnchorLink>{' '}
                and choose a default provider — if it fails, we’ll send the transaction to another
                one.
              </Trans>
              <SectionActions>
                <SignUpButton variant="dark">
                  <Trans>Get MONEI Now</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
            <SectionImage
              src={index1}
              alt="Secure payment gateway"
              title="Secure payment gateway"
              width={500}
              height={500}
              mobileWidth={280}
            />
          </Section>
          <Section>
            <SectionImage
              src={index3}
              alt="Secure payment gateway"
              title="Secure payment gateway"
              width={400}
              height={428}
              mobileWidth={180}
            />
            <div>
              <SectionHeader>
                <Trans>Pay the lowest PrestaShop payment gateway fees</Trans>
              </SectionHeader>
              <Trans parent="p">
                Save money on fees with a flexible{' '}
                <PrestashopPaymentGatewayLink>
                  PrestaShop payment gateway
                </PrestashopPaymentGatewayLink>
                . As you sell more, your <PricingLink>transaction fees</PricingLink> will decrease
                in real-time. You’ll have more money to reinvest in growing your e-commerce
                business.
              </Trans>
              <SectionActions>
                <SignUpButton variant="dark">
                  <Trans>Open an Account</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
          </Section>
        </Content>
      </Background>
      <SetupIntegration>
        <SectionHeader underline tagName="h3">
          <Trans>Set up your PrestaShop Bizum payment integration</Trans>
        </SectionHeader>
        <Trans parent="p">
          Adding Bizum to your PrestaShop store only takes a few minutes. Follow these steps:
        </Trans>
        <OrderedList>
          <Trans parent="li">
            <SignUpLink>Sign up for MONEI</SignUpLink>
          </Trans>
          <Trans parent="li">
            <AnchorLink href="https://support.monei.com/hc/articles/360018047698-How-do-I-activate-the-Bizum-payment-method-">
              Activate Bizum payments
            </AnchorLink>{' '}
            in your MONEI dashboard
          </Trans>
          <Trans parent="li">
            <AnchorLink href="https://docs.monei.com/docs/e-commerce/prestashop/">
              Connect MONEI
            </AnchorLink>{' '}
            to your PrestaShop store
          </Trans>
        </OrderedList>
      </SetupIntegration>
    </>
  );
};

export default BizumForPrestashop;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {ns: {in: ["common", "bizum-for-prestashop"]}, language: {eq: $language}}
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
